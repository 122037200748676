<template>
  <body id="poster">
  <el-form class="login-container" label-position="left" label-width="0px">
    <h3 class="login_title">中维动力-数字大屏登陆</h3>
    <el-form-item>
      <el-input type="text" v-model="loginForm.username" auto-complete="off" placeholder="账号"></el-input>
    </el-form-item>

    <el-form-item>
      <el-input type="password" v-model="loginForm.password" auto-complete="off" placeholder="密码"></el-input>
    </el-form-item>

    <el-form-item style="width: 100%">
      <el-button v-loading="loading" type="primary" style="width: 100%;background: #505458;border: none" v-on:click="login">登录</el-button>
    </el-form-item>
  </el-form>
  </body>
</template>


<script>
export default {
  name: "Login",
  data() {
    return {
      loading:false,
      loginForm: {
        username: '',
        password: '',
      },
      responseResult: []
    }
  },
  mounted() {
    //清理缓存
    localStorage.removeItem('token')
  },
  methods: {
    login() {

      this.loading = true
      this.$api.user.login({
        ...this.loginForm
      }).then(res=>{
        console.log(res);
        this.$message({
          message: '登陆成功,即将跳转',
          type: 'success'
        });
        setTimeout(()=>{
          this.$router.push('/') // 跳转到首页
        }, 2000)

      }).catch(err=>{
        console.log(err)
        this.$message({
          message: `登陆失败,原因:${err}`,
          type: 'error'
        });
      }).finally(()=>{
          this.loading = false
      })
    }
  }
}
</script>

<style>
#poster {
  background:url("../assets/pageBg.png") no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
  background-size: cover;
  position: fixed;
}
body{
  margin: 0px;
  padding: 0;
}

.login-container {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 300px auto;
  width: 350px;
  padding: 35px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 15px #cac6c6;
}

.login_title {
  margin: 0px auto 40px auto;
  text-align: center;
  color: #505458;
}


</style>